<template>
  <div class="trajectory-main">
    <div class="trajectory-main-query">
      <el-form :model="form" :rules="rules" ref="form" label-position="right"
               label-width="106px" style="padding: 0 10px">
          <el-form-item label="标段名称" prop="bidId">
          <el-cascader
            v-model="form.bidId"
            placeholder="请选择"
            :options="bidList"
            :show-all-levels="false"
            filterable
            style="width: 200px"
            @change ="onBidId"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="设备应用对象" prop="applicationObject">
          <el-select v-model="form.applicationObject"
                     placeholder="请选择"
                     filterable
                     @change="onApplication"
                     >
            <el-option v-for="(item, idx) in $enums_hw.applicationObjectType.list"
                       :label="item.label"
                       :value="item.value"
                       :key="idx"/>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号/姓名" prop="deviceId">
          <el-select v-model="form.deviceId" 
                      placeholder="请选择车牌" 
                      filterable 
                      ref="select"
                      :loading="loading"
                      @visible-change="onCarPersonnelList" 
                      >
            <el-option
                    v-for="(item, idx) in carList"
                    :key="idx"
                    :label="item.label"
                    :value="item.device"
                    :disabled="item.deviceStatus==='无设备'">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间设置" prop="dateType">
          <el-select v-model="form.dateType"
                     placeholder="请选择"
                     @change="selectChange"
                     filterable
                     >
            <el-option v-for="(item, idx) in $enums.SetDateList"
                       :label="item"
                       :value="item"
                       :key="idx"/>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
                  v-model="form.startTime"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="选择开始时间"
                  @change="clickStartTime"
                  :disabled="form.dateType!=='自定义时间'">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
                  v-model="form.endTime"
                  value-format="timestamp"
                  type="datetime"
                  placeholder="选择结束时间"
                  @change="clickEndTime"
                  :disabled="form.dateType!=='自定义时间'">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="text-align: right; padding-right: 18px">
        <!--submit-->
        <el-button type="primary" @click="submit" :disabled="isDisabled">查询</el-button>
      </div>
    </div>
    <div class="trajectory-main-map">
      <div class="main-map" id="container">
        <div class="map-control">
          <div style="text-align: center; color: #333;margin-bottom: 20px;">轨迹回放控制</div>
          <el-button size="mini" type="primary" @click="startAnimation">开始播放</el-button>
          <el-button size="mini" type="primary" @click="stopAnimation">暂停播放</el-button>
          <el-button size="mini" type="primary" style="margin-left: 0;margin-top: 10px" @click="initAnimation">继续播放
          </el-button>
          <el-button size="mini" type="primary" @click="fastAnimation">快速播放</el-button>
        </div>
      </div>
      <div class="main-table">
        <div class="table-tabs">
          <!-- <div v-for="(item, idx) in ['作业信息','静止点/停车点', '报警列表']" :key="idx" class="curp" -->
          <div v-for="(item, idx) in ['静止点/停车点', '报警列表']" :key="idx" class="curp"
               :class="{'tabs-active': tabsActive===item}"
               @click="clickTable(item)">{{item}}
          </div>
        </div>
        <div class="table">
          <!-- <ayl-table :table="jobInformationTable" v-show="tabsActive==='作业信息'"/> -->
          <ayl-table :table="stopTable" v-show="tabsActive==='静止点/停车点'"/>
          <ayl-table :table="alarmTable" v-show="form.applicationObject === 0 && tabsActive==='报警列表'"/>
          <ayl-table :table="personnelTable" v-show="form.applicationObject !== 0 && tabsActive==='报警列表'"/>
        </div>
      </div>
    </div>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
      <div class="attachment-main">
        <div class="left-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%"
                 v-if="videoIdx === 1"></video>
          <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else>
        </div>
        <div class="right-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images"
                 @click="videoItem(1)"></video>
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import { coordinate, stopPoint, alarmPoint } from './dataList'

  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  const _s = require('../../../../assets/images/starting.png')
  const _e = require('../../../../assets/images/end.png')
  const _st = require('../../../../assets/images/tingche.png')
  const _Ar = require('../../../../assets/images/bj.png')
  export default {
    data () {
      const vm = this
      return {
        isDisabled: false,
        // 查询数据
        loading: false,
        carList: [],
        bidList: [],
        form: {
          bidId: this.$route.query.bidId || null,
          applicationObject: this.$route.query.objectType || null,
          deviceId: this.$route.query.deviceId || '',
          dateType: '今天',
          startTime: new Date().setHours(0, 0, 0, 0),
          endTime: new Date().setHours(23, 59, 59, 99)
        },
        rules: {
          bidId: {required: true,message: "请选择标段名称",trigger: "change"},
          applicationObject: {required: true, message: '请选择', trigger: 'change'},
          deviceId: {required: true, message: '请选择', trigger: 'change'},
          startTime: {required: true, message: '请选择开始时间', trigger: 'change'},
          endTime: {required: true, message: '请选择结束时间', trigger: 'change'},
          dateType: {required: true, message: '请选择时间设置', trigger: 'change'},
        },
        // tabsActive: '作业信息',
        tabsActive: '静止点/停车点',
        
        // 地图数据
        map: null,
        center: null,
        mapMarker: [],
        mapStopMarker: [],
        mapStopMarkerData: [],
        mapAlarmMarker: [],
        mapAlarmMarkerData: [],
        polyline: null,
        // 速度
        speed: null,
        speed_f: null,

        //作业信息列表
        jobInformationTable: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api_hw.historyVideo_listWorkInfo,
          query: null,
          // query 查询当前列表页数据的参数
          // query: {
          //   plateColor: null,
          //   carStatus: null,
          //   startTime: '',
          //   endTime: '',
          //   queryContent: null,
          // },
          columns: [
            {
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
              title: '姓名/车牌号',
              width: '120px',
              key: 'name',
          }, {
              title: '所属企业',
              width: '180px',
              key: 'companyName',
          }, {
            title: '所属标段',
            width: '160px',
            key: 'bidName',
          }, {
            title: '所谓网格/线路',
            width: '130px',
            key: 'gridOrRouteName',
          }, {
            title: '排班驾驶员',
            width: '120px',
            key: 'arrangeDriver',
          }, {
            title: '实际驾驶员',
            key: 'realDriver',
            width: '120px',
          }, {
            title: '日期',
            filter: 'str2ymdhm',
            key: 'date',
            width: '180px',
          }, {
            title: '班次时段',
            key: 'workTime',
            width: '180px',
          }, {
            title: '作业开始时间',
            filter: 'str2ymdhm',
            key: 'workStartTime',
            width: '180px',
          }, {
            title: '作业结束时间',
            filter: 'str2ymdhm',
            key: 'workEndTime',
            width: '180px',
          }, {
            title: '实际作业时长',
            key: 'realWorkTime',
            width: '140px',
          }, {
            title: '应作业里程(km)',
            key: 'shouldWorkMileage',
            width: '130px',
          }, {
            title: '实际作业里程(km)',
            width: '140px',
            key: 'realWorkMileage',
          }]
        },
        // 停车点列表
        stopTable: {
          //hidePagination: true,
          api: vm.$api_hw.historyVideo_getStopPointList,
          query: null,
          // query: {
          //   deviceId: null,
          //   startTime: null,
          //   endTime: null,
          // },
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '姓名/车牌号',
            key: 'name',
            width: '120px',
          }, {
            width: '120px',
            title: '排班驾驶员',
            key: 'arrangeDriver',
          },  {
            width: '120px',
            title: '实际驾驶员',
            key: 'realDriver',
          },{
            title: '所属企业',
            key: 'companyName',
            showTooltip: true,
          },{
            title: '所属标段',
            key: 'bidName',
            showTooltip: true,
          },{
            title: '位置',
            key: 'position',
            showTooltip: true,
          }, {
            title: '开始时间',
            key: 'stopStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '结束时间',
            key: 'stopEndTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '停车时长',
            key: 'stopTime',
          }, {
            title: '操作',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '静止点/停车点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },
        // 报警点列表
        alarmTable: {
          hidePagination: true,
          api: vm.$api.LsitAlarmDeal,
          query: null,
          // query: {
          //   deviceId: null,
          //   alarmIdentifierList: null,
          //   alarmType: null,
          //   dealStatus: null,
          //   dealName: null,
          //   startTime: null,
          //   endTime: null,
          //   queryContent: null
          // },
          columns: [{
              title: '序号',
              width: '50px',
              $index: 'index',
            },
            {
              title: '车牌号',
              key: 'plateNumber',
              width: '120px',
            }, {
              title: '所属企业',
              showTooltip: true,
              key: 'company',
            }, {
              title: '所属标段',
              showTooltip: true,
              key: 'bidName',
            }, {
              title: '驾驶员姓名',
              width: '100px',
              key: 'driverName',
            }, {
              title: '报警类型',
              width: '140px',
              key: 'alarmIdentifier',
            }, {
              title: '报警等级',
              width: '100px',
              key: 'alarmType',
            }, {
              title: '报警开始时间',
              key: 'alarmStartTime',
              filter: 'str2ymdhm',
              width: '170px',
            }, {
              title: '处理状态',
              width: '100px',
              key: 'dealStatusDes',
            }, {
              title: '处理人',
              width: '100px',
              key: 'dealUserName',
            }, {
            title: '附件',
            render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': ctx.row.attache === 1 ? true : false,
                    },
                    on: {
                      click: ctx.row.attache === 1 ? vm.goPreview.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                    }
                  }, ctx.row.attache === 1 ? '预览' :  '暂无')
                ])
              }
          }, {
            title: '操作',
            width: '180px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view-hw': true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看详情'),
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '报警点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },

        //人员报警
        personnelTable: {
          api: vm.$api_hw.alarmManage_listPersonAlarmDeal,
          query: null,
          // query: {
          //   bidId: null,
          //   alarmIdentifierList: [],
          //   startTime: null,
          //   endTime: null,
          //   queryContent: null,
          // },
          // 是否显示分页
          //hidePagination: true,
          columns: [
              {
              title: '序号',
              width: '50px',
              $index: 'index',
            },
            {
              title: '姓名',
              key: 'driverName',
              width: '120px',
            }, {
              title: '所属企业',
              showTooltip: true,
              key: 'company',
            }, {
              title: '所属标段',
              showTooltip: true,
              key: 'bidName',
            }, {
              title: '所属网格',
              width: '100px',
              key: 'gridName',
            }, {
              title: '报警类型',
              width: '140px',
              key: 'alarmIdentifier',
            }, {
              title: '报警等级',
              width: '100px',
              key: 'alarmType',
            }, {
              title: '报警开始时间',
              key: 'alarmStartTime',
              filter: 'str2ymdhm',
              width: '170px',
            }, {
              title: '报警位置',
              key: 'location',
            }, {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': true,
                    },
                    on: {
                      click: vm.personnelDetails.bind(this, ctx.row)
                    }
                  }, '查看'),
                ])
              }
            }]
        },
        coordinateList: [],
        stopPointList: [],
        alarmPointList: [],

        dialogFormVisible: false,
        url: '',
        videoUrl: '',
        videoIdx: 1,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          images: [],
          poster: '', //你的封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        },
      }
    },
    methods: {
      onSearchChange (e) {
        this.$search(this.table, e)
        if (!this.table.hidePagination) {
          this.$set(this.table.pagination, 'currentPage', 1)
        }
      },

      //修改标段名称
      onBidId() {
        this.form.applicationObject = null
        this.form.deviceId = null
      },
      //修改设备应用对象
      onApplication() {
        this.form.deviceId = null
      },
      //获取车辆/人员下拉列表
      async onCarPersonnelList(flag) {
        if(flag) {
          if(this.form.bidId !== null ){
            if(this.form.applicationObject !== null) {
              this.loading = true;
              const oldCarList =  await this.$api_hw.historyVideo_getRealTimeDeviceInfoList({
                bidId: this.$route.query.bidId || this.form.bidId[1],
                objectType: this.$route.query.objectType || this.form.applicationObject,
                queryContent: null,
              })
              const carList = this.$_.cloneDeep(oldCarList)
              this.carList = this.$utils.getTreeAllData(carList)
              setTimeout(() => {
                this.loading = false
              },200)
            } else {
              this.$message({
                message: '请选择设备应用对象',
                type: 'warning'
              });
            }
          }else {
            this.$message({
              message: '请选择标段',
              type: 'warning'
            });
          }
        } else {
          return
        }
      },
      async submit () {
        // this.getStopTableList()
        this.$refs.form.validate(async valid => {
          if (this.mapMarker.length > 0) {
            this.map.remove(this.mapMarker)
            this.map.remove(this.polyline)
          }
          if (!valid) return
          try {
            this.isDisabled = true
            this.coordinateList = []
            this.stopPointList= []
            this.alarmPointList = []
             this.initAlarmPoint([])
            const data = await this.$api_hw.historyVideo_getDeviceHistoryUsage({
              bidId: this.form.bidId[1],
              objectType: this.form.applicationObject,
              deviceId: this.form.deviceId,
              startTime: this.form.startTime,
              endTime: this.form.endTime,
            })
            this.coordinateList = data.coordinateList
            this.stopPointList = data.stopPointList
            this.alarmPointList = data.alarmPointList
            this.initCoordinate(this.coordinateList)
            this.initStopPoint(this.stopPointList)
            this.initAlarmPoint(this.alarmPointList)
            
            await this.getJobInformationTableList()
            await this.getStopTableList()
            if(this.form.applicationObject === 0){
              await this.getAlarmTableList()
            }else {
              await this.getPersonnelTableList()
            }
            
          } catch (e) {
            //console.log(e)
          }
          setTimeout(() => {
            this.isDisabled = false
          }, 3000)
        })
      },
      // 绘制轨迹
      initCoordinate (c) {
        // 绘制起点
        this.mapMarker[0] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: _s,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制终点
        this.mapMarker[1] = new AMap.Marker({
          map: this.map,
          position: c[c.length - 1],
          icon: _e,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制车辆
        this.mapMarker[2] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: onLineIcon,
          offset: new AMap.Pixel(-10, -15),
          autoRotation: true,
          // angle: 180,
        })
        // 绘制线路
        this.polyline = new AMap.Polyline({
          map: this.map,
          path: c,
          showDir: true,
          isOutline: true,
          outlineColor: '#cccccc',
          strokeColor: '#1e9eea',
          strokeWeight: 8,
          lineJoin: 'round',
        })
        this.map.setFitView()
        // 获取轨迹的总里程
        const mileage = Math.round(this.polyline.getLength() / 1000)
        /*
        * 根据设备上报的点来计算速度
        * 正常播放：点与点间隔时间为1s；
        * 快速播放：点与点间隔时间为0.25s.
        * */
        const num = (c.length / 60 / 60).toFixed(3)
        this.speed = Math.round(mileage / num)

        const num_f = (c.length * 0.25 / 60 / 60).toFixed(3)
        this.speed_f = Math.round(mileage / num_f)

        // console.log(this.speed, this.speed_f)

        this.mapMarker[2].moveAlong(c, this.speed)
      },
      // 绘制停车点
      initStopPoint (s) {
        this.mapStopMarkerData.map(v =>{
          this.map.remove(v)
        })
        s.forEach((e, i) => {
          this.mapStopMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            icon: _st,
          })
          this.mapStopMarkerData.push(this.mapStopMarker[i])
        })
      },
      // 绘制报警点
      initAlarmPoint (a) {
        this.mapAlarmMarkerData.map( v => {
          this.map.remove(v)
        })
        //console.log(a)
        a.forEach((e, i) => {
          this.mapAlarmMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            offset: new AMap.Pixel(-10, -26),
            icon: _Ar,
          })
          this.mapAlarmMarkerData.push(this.mapAlarmMarker[i])
        })
      },
      // 请求作业信息
      async getJobInformationTableList () {
        this.jobInformationTable.query = {
          bidId: this.form.bidId[1],
          objectType: this.form.applicationObject,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
        }
        await this.$search(this.jobInformationTable)
      },
      // 请求停车点
      async getStopTableList () {
        this.stopTable.query = {
          bidId: this.form.bidId[1],
          objectType: this.form.applicationObject,
          deviceId: this.form.deviceId,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
        }
        await this.$search(this.stopTable)
      },
      // 请求报警点
      async getAlarmTableList () {
        this.alarmTable.query = {
          deviceId: this.form.deviceId,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          alarmIdentifierList: [],
          dealStatus: null,
          alarmType: null,
          dealName: null,
          queryContent: null,
        }
        // this.alarmTable.query.deviceId = this.form.deviceId
        // this.alarmTable.query.startTime = this.form.startTime
        // this.alarmTable.query.endTime = this.form.endTime
        // this.alarmTable.query.queryContent = this.$refs.select.query.substring(0, 7)
        await this.$search(this.alarmTable)
      },
      //请求人员报警列表
      async getPersonnelTableList () {
        this.personnelTable.query = {
          bidId: this.form.bidId[1],
          alarmIdentifierList: [],
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          queryContent: null,
        }
        await this.$search(this.personnelTable)
      },
      // 开始播放
      startAnimation () {
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed)
        // this.mapMarker[2].resumeMove(coordinate, 500)
      },
      // 暂停播放
      stopAnimation () {
        this.mapMarker[2].pauseMove()
      },
      // 继续
      initAnimation () {
        this.mapMarker[2].resumeMove()
      },
      // 快速
      fastAnimation () {
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed_f)
      },
      // 查看位置
      viewPosition (i, v) {
        v === '报警点' ? Animation(this.map, this.mapAlarmMarker, i) : Animation(this.map, this.mapStopMarker, i)

        function Animation (map, markerArr, index) {
          const lng = markerArr[index].getPosition()
          map.setZoomAndCenter(14, [lng.lng, lng.lat])
          markerArr[index].setAnimation('AMAP_ANIMATION_BOUNCE')
          setTimeout(() => {
            markerArr[index].setAnimation('AMAP_ANIMATION_NONE')
          }, 3500)
        }

      },
      goDetail (val) {
        this.$router.push({
          path:'/alarm-management/alarm-center/alarm-dispose-details',
          query: {
            deviceId: val.deviceId,
            alarmStartTime: val.alarmStartTime,
            alarmType: val.alarmType,
            tenantId: val.tenantId,
            alarmName: val.alarmIdentifier
          }
        })
      },
      // 初始化地图
      initMap () {
        this.map = new AMap.Map('container', {
          center: this.center,
          resizeEnable: true,
          zoom: 13,
        })
      },
      clickTable (e) {
        if (this.tabsActive === e) return
        this.tabsActive = e
        if (!this.form.deviceId) return
        e === '作业信息' ? this.getJobInformationTableList() : e === '静止点/停车点' ? this.getStopTableList() : this.form.applicationObject === 0 ? this.getAlarmTableList() : this.getPersonnelTableList()
      },
      clickStartTime () {
        if (this.form.endTime && this.form.startTime && this.form.startTime > this.form.endTime) {
          this.$message.warning('开始时间不能大于结束时间')
          this.form.endTime = ''
        }
        const num = this.form.endTime - this.form.startTime
        if (this.form.endTime && this.form.startTime && Math.abs(num / (60 * 60 * 24 * 1000)) > 7) {
          this.$message.warning('选择的时间区域不可以大于7天')
          this.form.endTime = ''
        }
      },
      clickEndTime () {
        if (this.form.endTime && this.form.startTime && this.form.startTime > this.form.endTime) {
          this.$message.warning('开始时间不能大于结束时间')
          this.form.endTime = ''
          return
        }
        const num = this.form.endTime - this.form.startTime
        if (this.form.endTime && this.form.startTime && Math.abs(num / (60 * 60 * 24 * 1000)) > 7) {
          this.$message.warning('选择的时间区域不可以大于7天')
          this.form.endTime = ''
        }
      },
      //  时间设置
      selectChange (v) {
        switch (v) {
          case '自定义时间':
            this.form.startTime = ''
            this.form.endTime = ''
            break
          case '今天':
            this.form.startTime = new Date().setHours(0, 0, 0, 0)
            this.form.endTime = new Date().setHours(23, 59, 59, 99)
            break
          case '昨天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24)
            this.form.endTime = new Date().setTime(new Date(new Date().setHours(23, 59, 59, 99)).getTime() - 3600 * 1000 * 24)
            break
          case '最近两天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24 * 2)
            this.form.endTime = new Date().setHours(0, 0, 0, 0)
            break
          case '最近七天':
            this.form.startTime = new Date().setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 3600 * 1000 * 24 * 7)
            this.form.endTime = new Date().setHours(0, 0, 0, 0)
            break
        }
      },
      async goPreview (val) {
        if (val.attache === 0) return
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        this.playerOptions.sources = videoData[0].sources
        this.videoUrl = this.playerOptions.sources[0].src
      },
      goNull() {},
      //人员报警查看详情
      personnelDetails(val) {
        this.$router.push({path:'/alarm-management/alarm-center/alarm-personnel-details',
        query: {
          alarmName: val.alarmName,
          alarmStartTime: val.alarmStartTime, 
          alarmType: val.alarmType,
          deviceId: val.deviceId, 
        }
        });
      },
      chg: function (ind, val) {
        this.url = this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem (val) {
        this.videoIdx = val
      }
    },
    async mounted () {
      //获取所属标段下拉
      this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
      // 获取后台数据的车辆列表
      //const oldCarList1 = await this.$api.getRealTimeCarInfoList({})
      //console.log(oldCarList1)
      // 深度克隆，不直接引用后台的值
      //const carList = this.$_.cloneDeep(oldCarList)
      // 调用递归, 获取所有公司下的车辆
      //this.carList = this.$utils.getTreeAllData(carList)
      this.initMap()
      /**
       * 判断是否从实时监管跳转过来
      */
     
     if(this.$route.query.type === 1) {
      this.form.applicationObject = this.$route.query.objectType

      const oldCarList =  await this.$api_hw.historyVideo_getRealTimeDeviceInfoList({
        bidId: this.$route.query.bidId,
        objectType: this.$route.query.objectType,
        queryContent: null,
      })
      const carList = this.$_.cloneDeep(oldCarList)
      this.carList = this.$utils.getTreeAllData(carList)
      setTimeout(() => {
        this.loading = false
      },200)


      if (this.mapMarker.length > 0) {
        this.map.remove(this.mapMarker)
        this.map.remove(this.polyline)
      }
      try {
        const data = await this.$api_hw.historyVideo_getDeviceHistoryUsage({
          bidId: this.$route.query.bidId,
          objectType: this.$route.query.objectType,
          deviceId: this.$route.query.deviceId,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
        })
        this.coordinateList = data.coordinateList
        this.stopPointList = data.stopPointList
        this.alarmPointList = data.alarmPointList
        this.initCoordinate(this.coordinateList)
        this.initStopPoint(this.stopPointList)
        this.initAlarmPoint(this.alarmPointList)
        
        //await this.getJobInformationTableList()
        await this.getStopTableList()
        // if(this.form.applicationObject === 0){
        //   await this.getAlarmTableList()
        // }else {
        //   await this.getPersonnelTableList()
        // }
      } catch (e) {
        //console.log(e)
      }
      

      // //请求停车点
      // this.stopTable.query = {
      //     bidId: this.$route.query.bidId,
      //     objectType: this.$route.query.objectType,
      //     deviceId: this.$route.query.deviceId,
      //     startTime: this.form.startTime,
      //     endTime: this.form.endTime,
      //   }
      //   await this.$search(this.stopTable)
     }

      
    }
  }
</script>

<style lang='sass' scoped>
  .trajectory-main
    width: 100%
    height: 100%
    padding-top: 6px

    & > div
      display: inline-block
      height: 100%
      vertical-align: top

    &-query
      width: 330px
      padding-top: 15px

      /deep/ .el-date-editor.el-input,
      /deep/ .el-select
        width: 200px

    &-map
      width: calc(100% - 330px)

      & > div
        width: 100%

      .main-map
        height: 482px
        position: relative

        .map-control
          position: absolute
          bottom: 10px
          right: 10px
          z-index: 2
          width: 200px
          height: 126px
          background: rgba(255, 255, 255, 1)
          box-shadow: 0 1px 5px 0 rgba(8, 88, 169, 0.3)
          border-radius: 4px
          padding: 15px 15px 14px

      .main-table
        margin-top: 10px
        height: calc(100% - 482px - 10px)

        .table-tabs
          .tabs-active
            color: $-color-primary-3

          div
            display: inline-block
            padding: 10px 14px
            color: $-font-color-2
            position: relative
            font-size: $fontX

            &:hover
              color: $-color-primary-3

            & + div:after
              content: ' '
              position: absolute
              left: 0
              top: 12px
              width: 1px
              height: 16px
              background-color: $grayShallow3

        .table
          height: calc(100% - 38px)

    /deep/ .el-form-item
      width: 100%

    .attachment-main
      width: 100%

      .right-img
        width: 20%
        height: 520px
        display: inline-block
        vertical-align: top
        margin-left: 2%
        overflow-y: auto

        .images
          width: 100%

      .left-img
        width: 78%
        height: 520px
        display: inline-block

        .images
          width: 100%
          height: 100%

      .class
        display: block

      .show
        display: none
</style>
